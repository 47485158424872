export default {
  handleFailedPermission(router) {
    console.log('failed permission')
    router.replace('/')
  },
  beforeCreate() {
    const hasPermissionToLoad = this.$getResolvedPermission({
      can: this.$options.can,
      canAtSchool: this.$options.canAtSchool,
      canAtAnySchool: this.$options.canAtAnySchool,
      schoolId: this.$options.schoolId,
      or: true,
    })

    if (!hasPermissionToLoad) {
      this.$options.handleFailedPermission(this.$router)
    }
  },
}