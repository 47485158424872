import Vue from 'vue'
import App from './App.vue'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// VUE-ROUTER - https://router.vuejs.org/
import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import './plugins/vue-google-maps'
import './plugins/vue-shortkey'
import './plugins/vue-head'
import './plugins/vue-gtag'
import './plugins/apexcharts'
import './plugins/echarts'
import './plugins/animate'
import './plugins/clipboard'
import './plugins/moment'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/formatCurrency'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'

// Animation library - https://animate.style/
import 'animate.css/animate.min.css'

// MDI for offline use
import '@mdi/font/css/materialdesignicons.min.css';

import { onAuthStateChanged } from 'firebase/auth';
import './registerServiceWorker';
import FunctionsService from './services/FunctionsService';
import { appConfig } from '@/app-config';
import { version } from './version';
import * as Sentry from '@sentry/vue';
import { initializeFirebaseServices, db, auth, storage, resetFirebase } from './firebase-setup'

Sentry.init({
  Vue,
  dsn: 'https://a5dfa660503b496da529d36303f1e959@o4507569233461248.ingest.us.sentry.io/4507569239162880',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: appConfig.projectId,
  release: version,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/yourserver\.io\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend(event, hint) {
    if (hint && hint.originalException && hint.originalException.message === 'The client has already been terminated.') {
      store.commit('app/setIsUnexpectedResetDialogVisible', true);
    }
    // Don't send localhost events
    if (window.location.hostname === 'localhost') {
      console.error('Sentry error supppressed for localhost:', event, hint);
      return null;
    }

    // These errors are handled, despite surfacing in console
    const ignoredErrorMessages = [
      'Redirected when going from',
      'Navigation cancelled',
      'Avoided redundant navigation to current location:',
      "SyntaxError: Unexpected token '<'",
    ]
    const errorMessage = hint && hint.originalException && hint.originalException.message;
    if (errorMessage && ignoredErrorMessages.some(ignoredMessage => errorMessage.includes(ignoredMessage))) {
      return null;
    }

    return event;
  },
});

initializeFirebaseServices();

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  i18n,
  db,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

Vue.prototype.db = db;
Vue.prototype.auth = auth;
Vue.prototype.storage = storage;
Vue.prototype.runFunction = FunctionsService.run;
Vue.prototype.$can = (permission) => {
  const user = store.state.app.user;
  if (!user) {
    return false;
  }
  return user.can(permission);
}
Vue.prototype.$canAtSchool = (permission, schoolId) => {
  const user = store.state.app.user;
  if (!user) {
    return false;
  }
  return user.canAtSchool(permission, schoolId);
}
Vue.prototype.$canAtAnySchool = (permission) => {
  const user = store.state.app.user;
  if (!user) {
    return false;
  }
  return user.canAtAnySchool(permission);
}
Vue.prototype.$getResolvedPermission = ({ can, canAtSchool, canAtAnySchool, schoolId }) => {
  const user = store.state.app.user;
  if (!user) {
    return false;
  }
  return user.getResolvedPermission({ can, canAtSchool, canAtAnySchool, schoolId });
}
