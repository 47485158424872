<template>
  <div
    class="d-flex flex-grow-1"
  >
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="menuTheme === 'light'"
      :dark="menuTheme === 'dark'"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="px-4 pt-4">
          <v-img :src="require('@/assets/images/logo.png')" color="transparent" contain></v-img>
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="navigation.menu" />

      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div v-if="navigation.footer.length" class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            :href="item.href"
            :target="item.target"
            small
            text
          >
            {{ item.key ? $t(item.key) : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-dialog v-model="isOfflineDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          You are offline
        </v-card-title>
        <v-card-text>
          Most elements of SchoolDog will continue to work until you reconnect, and you may continue to record observation entries. However, please note the following:
          <ul>
            <li>If you are using SchoolDog from a browser, do NOT attempt to refresh until you regain connection.</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isOfflineDialogVisible = false" color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isManuallyOfflineDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          You are in Offline Mode
        </v-card-title>
        <v-card-text>
          Offline Mode streamlines SchoolDog for use in areas with limited or no network access. While in Offline Mode, you can still start SchoolDog Walks and record observation entries. However, changes will only be saved to your local device. Once you regain stable network access, disable Offline Mode to sync your data to the internet.
          <div class="mt-2">
            <v-btn @click="setManualOfflineMode(false); isManuallyOfflineDialogVisible = false;" color="primary" outlined>Disable Offline Mode</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isManuallyOfflineDialogVisible = false" color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="toolbarTheme === 'light'"
      :dark="toolbarTheme === 'dark'"
    >
      <v-card class="flex-grow-1 d-flex" :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']" :flat="!isToolbarDetached">
        <div class="d-flex flex-grow-1 align-center">

          <div class="d-flex flex-grow-1 align-center">

            <v-btn v-if="isRunningAsPwa" @click="goBack()" color="grey darken-1" icon x-small>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <img v-if="$vuetify.breakpoint.mdAndUp" :src="require('@/assets/images/logo.svg')" style="max-height: 32px;" />
            <v-spacer></v-spacer>
            <v-btn v-if="isManuallyOffline" @click="isManuallyOfflineDialogVisible = true" color="warning darken-1" rounded class="mr-1">
              Offline Mode
              <v-icon>mdi-wifi-off</v-icon>
            </v-btn>
            <v-btn v-else-if="isOffline" @click="isOfflineDialogVisible = true" color="error" rounded class="mr-1">
              Offline
              <v-icon>mdi-wifi-off</v-icon>
            </v-btn>

            <v-btn v-if="!isOffline && user && (user.canAtAnySchool('be proposed tasks') || user.canAtAnySchool('create blank tasks') || user.can('create tasks from observations'))" outlined rounded to="/tasks" color="grey darken-1" class="mr-1">
              <v-icon class="mr-1">mdi-clipboard-text</v-icon>
              <span>Tasks</span>
              <v-badge v-if="numAssignedTasks" :color="numAssignedTasks ? 'primary' : 'grey darken-1'" :content="numAssignedTasks" inline offset-x="20" offset-y="20"></v-badge>
            </v-btn>
            <toolbar-user></toolbar-user>
            <v-btn v-if="isRunningAsPwa && !isOffline" @click="refresh()" color="grey darken-1" icon>
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <schooldog-updated-alert />
      <v-container :fluid="!isContentBoxed" style="padding: 0">
        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// navigation menu configurations
import config from '../configs'

import MainMenu from '../components/navigation/MainMenu';
import ToolbarUser from '../components/toolbar/ToolbarUser.vue';
import SchooldogUpdatedAlert from '../components/common/SchooldogUpdatedAlert.vue'

export default {
  components: {
    MainMenu,
    ToolbarUser,
    SchooldogUpdatedAlert,
  },
  data() {
    return {
      drawer: null,
      isOfflineDialogVisible: false,
      isManuallyOfflineDialogVisible: false,

      navigation: config.navigation,
    }
  },
  computed: {
    ...mapState('app', [
      'product',
      'isContentBoxed',
      'menuTheme',
      'toolbarTheme',
      'isToolbarDetached',
      'isOffline',
      'isManuallyOffline',
      'tasks',
      'user',
    ]),
    numAssignedTasks () {
      return this.tasks.filter(task => task.assignedToUserId === this.user.id && ![
        'Complete',
        'Declined',
      ].includes(task.status)).length
    },
    isRunningAsPwa() {
      return window.matchMedia('(display-mode: standalone)').matches
    },
  },
  methods: {
    ...mapActions('app', [
      'setManualOfflineMode',
    ]),
    goBack () {
      this.$router.go(-1)
    },
    refresh() {
      location.reload()
    },
  },
  watch: {
    isManuallyOffline (val) {
      if (val) {
        this.isManuallyOfflineDialogVisible = true
        if (this.user && !this.$route.path.startsWith('/walks')) {
          console.log(this.$route.path)
          this.$router.push('/walks')
        }
      }
    },
  },
}
</script>

<style scoped>
.buy-button {
  box-shadow: 1px 1px 18px #ee44aa;
}

::v-deep .v-app-bar {
  border-bottom: 4px solid var(--v-primary-base);
}
</style>
